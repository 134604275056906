@import '/src/styles/theme.scss'; 

.wrapper {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  position: relative;
  padding: 5vh;

  @media (max-width: $breakpoint-iphone14promax) {
    width: auto;
    padding: 25px;
    padding-top: 40px;
    margin-top: 15px;
  }
}

.rowWrapper {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 2vw;
  justify-content: center;
  align-items: center;
}

.picture {
  width: 200px;      
  height: 200px;  
  border-radius: 50%; 
  object-fit: cover;


  @media (max-width: $breakpoint-iphone14promax) {
    width: 90px;      
    height: 90px;  
  }
}

.mainContentWrapper {
  padding-top: 25px;
  padding-bottom: 50px;
}

.childIcon {
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.15);    
  }
}

