@import '/src/styles/theme.scss'; 

.contentWrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
}

.sidepaneWrapper {
  position: relative;
  height: 100vh;
  width: 18vw;
  transition: width 0.3s ease;
  background-color: $sidebar-background;

  @media (max-width: $breakpoint-iphone14promax) {
    width: 80vw;
  }

}

.childrenWrapper {
  width: 82vw;
  height: 100%;
  transition: width 0.3s ease;

  @media (max-width: $breakpoint-iphone14promax) {
    width: 20vw;
  }
}

.sidepaneToggle {
  top: 5px;
  left: 5px;
  cursor: pointer;
}

.hidden {
  width: 0;
  opacity: 0;
}

.childrenWrapper_fw {
  width: 100vw;
  transition: width 0.3s ease;
}


.sidepaneToggle {
  position: absolute;
  cursor: pointer;
  left: 10px;
  top: 13px;
}