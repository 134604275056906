@import '/src/styles/theme.scss'; 


.modalWrapper {
  z-index: 10;
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.modalOverlay {
  background-color: rgba(39, 39, 39, 0.63);
  backdrop-filter: blur(1px);
  height: 100%;
  position: fixed;
  transition: all ease 0.3s;
  width: 100%;
  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  position: relative;
  background-color: $main-white;
  box-shadow: $gray;
  border-radius: 8px;
  min-width: 30vw;
  min-height: 30vh;
  max-height: calc(100vh - (28px * 2));
  overflow: hidden;
  padding: 3px;
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: 1;

  @media (max-width: $breakpoint-iphone14promax) {
    padding: 0px;
    min-width: unset;
    width: 90%;  
    top: auto;
    margin: 0;
    max-height: calc(100vh - (14px * 2));
  }
}

.modalContainerAnimated {
  position: relative;
  background-color: $main-white;
  box-shadow: $gray;
  border-radius: 8px;
  min-width: 30vw;
  min-height: 30vh;
  max-height: calc(100vh - (28px * 2));
  overflow: hidden;
  padding: 3px;
  display: flex;
  flex-direction: column;
  top: 0;
  z-index: 1;

  @media (max-width: $breakpoint-iphone14promax) {
    padding: 5px;
    min-width: unset;
    width: 90%;  
    top: auto;
    margin: 0;
    max-height: calc(100vh - (14px * 2));
  }
}

.modalContent {
  // border-radius: 8px;
  background-color: $main-white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.modalContainerAnimated::before {
  content: "";
  position: absolute;
  width: 100vh;
  height: 100vh;
  top: -35vh;
  left: -20vh; 
  background: conic-gradient(from 0deg, $primary-color, $secondary-color, $tertiary-color); /* Animated gradient */
  // border-radius: 50%;
  z-index: -1;
  animation: rotate 6s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.headerComponent {
  z-index: 2;
  position: absolute;
  top: 20px;
  right: 25px;
  margin-left: auto;
  cursor: pointer;
}