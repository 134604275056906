

.mapboxgl-popup-content {
  border-radius: 20px;
  width: 300px;
  padding-top: 20px;  
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-close-button {
  top: 15px;
  right: 15px;
}
