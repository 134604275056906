@import '/src/styles/theme.scss'; 


.default {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 18px;
  @media (max-width: $breakpoint-iphone14promax) {
    font-size: small;
  }
}

.primary {
  color: $gray-1;
}

.currency {
  color: $primary-color;
}

.secondary {
  color: $secondary-color;
}

.chat {
  color: $main-white;
  opacity: 1;
  font-weight: 500;
  font-size: 18px;
}

.header {
  font-size: 30px;
  font-weight: 600;
  color: $gray;

  @media (max-width: $breakpoint-iphone14promax) {
    font-size: 20px;
  }
}

.subHeader {
  font-size: 18px;
  font-weight: 600;
  color: $gray-3;
  @media (max-width: $breakpoint-iphone14promax) {
    font-size: 18px;
  }
}

.small {
  font-weight: 300;
  color: $gray-1;
  font-size: 16px;
}

.smallHeader {
  font-weight: 600;
  color: $primary-color;
  font-size: 18px;
}

.bold {
  font-weight: 600;
}

.huge {
  font-size: 90px;
  font-weight: 1000;

  @media (max-width: $breakpoint-iphone14promax) {
    font-size: 40px;
  }
}

.big {
  font-size: 60px;
  font-weight: 1000;

  @media (max-width: $breakpoint-iphone14promax) {
    font-size: 40px;
  }
}

.bigItalic {
  font-style: italic;
  font-size: 48px;
  font-weight: 200;


  @media (max-width: $breakpoint-iphone14promax) {
    font-size: 32px;
  }
}

.italic {
  font-style: italic;
  font-size: 28px;
  font-weight: 400;
}


.headerWithShaddow {
  font-size: 30px;
  font-weight: 600;
  color: $gray;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: $breakpoint-iphone14promax) {
    font-size: 20px;
  }
}