@import '/src/styles/theme.scss'; 

.wrapper {
  width: 30vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: $main-white;

  @media (max-width: $breakpoint-iphone14promax) {
    width: auto;
    padding: 2vw;
    margin-top: 15px;
  }
}

.mainContentWrapper {
  padding-top: 25px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.childIcon {
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.15);    
  }
}

.loadingIcon {
  padding: 10% 0 0 0;
}

