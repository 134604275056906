
@import '/src/styles/theme.scss'; 


.popupWrapper {
  width: 100%;
}

.picture {
  margin-left: 25px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.headerWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.headerTextWrapper {
  padding-top: 10px;
  padding-left: 20px;
}

.listWrapper {
  margin-top: 25px;
  width: 280px;
}
