
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800;900;1000&display=swap');


$primary-color:  #0075C8;

$secondary-color: #FF50E0; 
$tertiary-color: #02BBFF; 
$quaternary-color: #FFDC00; 
//$light-green: #00BD5E;
$light-green: #53CE91;
$primary-light: rgba(73, 3, 226, .05);

$gray:#808080;
//$gray-1:#717070;
//$gray-2: #C9C9C9;
//$gray-3: #4A4A4A;

$gray:#808080;
$gray-1:#3f3e3e;
$gray-2: #999999;
$gray-3: #4A4A4A;
$gray-4: #252424;

$black: #000000;

$light-gray:#d2d4dc;


$main-white: #ffffff;

$breakpoint-iphone14promax: 480px;


// sidebar 

$sidebar-background: #F6F6F6;
$sidebar-border: #EEEEEE;

//chatbot 

$user-msg-border-color: #1C83C1;
$user-msg-background-color: #E6EFFC80;

$bot-msg-border-color: #C9C9C9;
$bot-msg-background-color: #FEFEFE80;
