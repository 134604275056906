@import '/src/styles/theme.scss'; 


.primary {
  padding: 10px;
  background-color: $primary-color;
  font-size: 16px;
  color: $main-white;
  font-weight: 500;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.05);    
  }

  @media (max-width: $breakpoint-iphone14promax) {
    padding: 10px;
  }
}

.secondary {
  padding: 5px;
  background-color: transparent;
  border: 1px solid $gray-2; 
  border-radius: 5px;
  font-size: 16px;
  color: $gray-1;
  font-weight: 500;
  background: #FEFEFE66;
  padding: 8px;
  width: 15vw;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);    
  }

  @media screen and (max-width: 600px) {
    padding: 10px;
  }
}

.tertiary {
  font-size: 18px;
  color: $main-white;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  height: 50px;
  width: 12vw;

  @media (max-width: $breakpoint-iphone14promax) {
    padding: 10px;
  }
}

.transparent {
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: $gray-3;
  font-weight: 400;
  cursor: pointer;
  text-align: left;
}

.selected {
  color: $secondary-color;
}

.confirm {
  padding: 5px;
  background-color: transparent;
  border: 1px solid $light-green; 
  border-radius: 5px;
  font-size: 16px;
  color: $main-white;
  font-weight: 500;
  background: $light-green;
  padding: 8px;
  //opacity: .5;
  width: 15vw;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);    
  }

  @media screen and (max-width: 600px) {
    padding: 10px;
  }
}


.cancel {
  padding: 5px;
  background-color: transparent;
  border: 1px solid $gray-2; 
  border-radius: 5px;
  font-size: 16px;
  color: $gray-1;
  font-weight: 500;
  background: #FEFEFE;
  padding: 8px;
  width: 15vw;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);    
  }

  @media screen and (max-width: 600px) {
    padding: 10px;
  }
}

.round {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0px 5px 0px 5px;
  margin: 0;
}


// background-image: linear-gradient(45deg, white, #b192ef);

.pressme {
  padding: 20px 30px 20px 30px;
  background-image: linear-gradient(75deg, #a7ddf3, #8e44ad);
  cursor: pointer;
  outline: none;
  border: 2px solid #8e44ad;
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  
  &:hover {
    opacity: 1;
    background-position: 100% 50%; 
    animation: gradientAnimation 5s linear infinite;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
    transform: translateY(-4px); 
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  }

 
}

 /* Keyframe animation for gradient movement */
 @keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
