@import '/src/styles/theme.scss'; 


.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
}

.labels {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: $breakpoint-iphone14promax) {
    :nth-child(n) {
      font-size: 18px;
    }
  }
}

.slider {
  width: 100%;
  height: 8px;
  background: #ddd;
  outline: none;
  transition: opacity 0.2s;
}
