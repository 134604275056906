@import '/src/styles/theme.scss'; 


.react-chatbot-kit-chat-btn-send,
.react-chatbot-kit-chat-bot-message-arrow, 
.react-chatbot-kit-user-chat-message-arrow,
.react-chatbot-kit-user-avatar-container,
.react-chatbot-kit-chat-header,
.react-chatbot-kit-chat-bot-avatar-container {
  display: none;
}

.react-chatbot-kit-chat-container {
  height: 100%;
  width: 100%;
  display: flex;
}

.react-chatbot-kit-chat-inner-container {
  height: calc(94vh - 1px);
  flex: 1;
  width: 50vw;
  background-color: transparent;
  display: flex;
  align-items: start;
  justify-content: center;

  @media (max-width: $breakpoint-iphone14promax) {
    width: 100vw;
    height: 85vh;
  }
}

.react-chatbot-kit-chat-message-container {
  background-color: transparent;
  width: 80%;
  height: 86%;

  @media (max-width: $breakpoint-iphone14promax) {
    height: 80%;
  }
}

.react-chatbot-kit-chat-message-container::-webkit-scrollbar {
  display: none;
}

.react-chatbot-kit-user-chat-message-container {
  justify-content: end;
}

.react-chatbot-kit-chat-bot-message-container {
  justify-content: start;
}

.react-chatbot-kit-user-chat-message {
  align-self: flex-end;
  border: 1px solid $user-msg-border-color;
  background-color: $user-msg-background-color;
  color: $gray-1;
  text-align: center;
  width: 100%;
  margin-left: 65%;
  @media (max-width: $breakpoint-iphone14promax) {
    margin-left: 35%;
  }
}

.react-chatbot-kit-chat-bot-message {
  margin-left: 0;
  width: 100%;
  border: 1px solid $bot-msg-border-color;
  background-color: $bot-msg-background-color;
  color: $gray-1;
  text-align: left;
  margin-right: 20%;
  padding: 25px;
}

.react-chatbot-kit-chat-input {
  position: absolute;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 15px;
  font-size: 16px;
  //bottom: 40px;
  bottom: 5vh;
  padding-left: 5vw;
  color: $gray-4; 
  font-family: 'Manrope', sans-serif;
  @media (max-width: $breakpoint-iphone14promax) {
    border-top: 2px solid #2682fc; 
  }    
}

.lineWrapper {
  position: absolute; 
  //bottom: 50px;
  bottom: 6vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $breakpoint-iphone14promax) {
    display: none;
    bottom: 7vh;
  }    
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 94vh;
  overflow:hidden;
  position: relative; 
}

.chatWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 94vh;
  overflow:hidden;
  position: relative; 

  @media (max-width: $breakpoint-iphone14promax) {
    justify-content: start;
  }    
}

.logoWrapper {
  border-radius: 50%;
}

.btnsWrapper {
  margin-top: 12vh;
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (max-width: $breakpoint-iphone14promax) {
    margin-top: 3vh;
  }
}

.hidden {
  display: none;
  width: 0;
  height: 0;
}

.box {
  width: 30%;
  height: 70%;
  background-color: rgba(73, 3, 226, .1);
  z-index: -1;
  filter: blur(100px);
  border-radius: 50%;
  position: absolute;
  left: 20vw;
  bottom: 10vh;
}

.inputWrapper {
  position: absolute; 
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  font-weight: 400;
  font-family: 'Manrope', sans-serif;

  @media (max-width: $breakpoint-iphone14promax) {
    border-top: 1px solid $gray-2; 
  }
}

.input {
  outline: none;
  border: none;
  background-color: transparent;
  color: $gray-4; 
  padding: 10px;
  font-size: 16px;
  height: 30px;
  width: 40vw;
  font-weight: 400;

  @media (max-width: $breakpoint-iphone14promax) {
    width: 90vw;
    //height: 40px;
    height: 40px;
    margin-left: 10px;
  }
}

.line {
  position: relative; 
  width: 60vw;

  @media (max-width: $breakpoint-iphone14promax) {
    width: 90vw;
  }
}
.line::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px; 
  background: radial-gradient(50% 50% at 50% 50%, #B1B1B1 36.5%, rgba(201, 201, 201, 0.5) 64.5%, rgba(244, 244, 244, 0.05) 95.5%);
}


.lineBlue {
  position: relative; 
  width: 60vw;
  @media (max-width: $breakpoint-iphone14promax) {
    width: 90vw;
  }
}
.lineBlue::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px; 
  background: radial-gradient(50% 50% at 50% 50%, #2682fc 36.5%, #2682fc 64.5%, rgba(244, 244, 244, 0.05) 95.5%);
}

.chatHeader {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;

  @media (max-width: $breakpoint-iphone14promax) {
    margin-top: 0vh;
  }

}

.oneLineLogo {
  margin-right: auto;
  padding-left: 9vw;
  width: 200px;
  height: 30px;
}

.bgCircleOne {
  z-index: -1;
  position: absolute;
  bottom: -30%;
  left: 25%;
  height: 800px;
  width: 800px;
  overflow: hidden;
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 161, 220, 0.4) 0%, rgba(0, 161, 220, 0.3) 40%, rgba(247, 241, 252, 0.25) 78.38%, rgba(249, 246, 252, 0) 100%);

  @media (max-width: $breakpoint-iphone14promax) {
    left: auto;
  }
}

.bgCircleTwo {
  z-index: -2;
  position: absolute;
  bottom: -30%;
  left: 40%;
  height: 800px;
  width: 800px;
  overflow: hidden;
  background: radial-gradient(50% 50% at 50% 50%, #E7D0FE 0%, #F0E3FD 39.56%, rgba(245, 237, 253, 0.5) 65.71%, rgba(247, 241, 252, 0.25) 78.38%, rgba(249, 246, 252, 0) 100%);
  @media (max-width: $breakpoint-iphone14promax) {
    left: auto;
  }
}

.bgCircleThree {
  z-index: -3;
  position: absolute;
  bottom: 18%;
  left: 43%;
  height: 400px;
  width: 400px;
  overflow: hidden;
  background: radial-gradient(50% 50% at 50% 50%, rgba(73, 3, 226, 0.4) 0%, rgba(73, 3, 226, 0.2) 39%, rgba(247, 241, 252, 0.1) 78%, rgba(249, 246, 252, 0) 100%);
  @media (max-width: $breakpoint-iphone14promax) {
    left: auto;
  }
}

.footerButtons {
  position: absolute;
  bottom: 5px;
  display: flex;
  gap: 20vw;
  @media (max-width: $breakpoint-iphone14promax) {
    bottom: 5vh;
  }
}

.sendIcon {
  display: none;

  @media (max-width: $breakpoint-iphone14promax) {
    display: block;
    position: absolute;
    right: 15px;
    bottom: 10vh;
    opacity: 1;
    transition: opacity 0.5s ease; 
  }
}