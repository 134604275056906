@import '/src/styles/theme.scss'; 


.HeaderWrapper {
  position: absolute;
  top:0;
  left: 0;
  z-index: 4;
  display: flex;
  flex-direction: row;
  height: 6vh;
  width: 100%;
  padding: 0;
  margin: 0;
  align-items: center;
  background-color: transparent;
  
  @media (max-width: $breakpoint-iphone14promax) {
    flex-direction: column;
    height: auto;
    align-items: start;
  }  
}

.headerBackground {
  position: absolute;
  top:0;
  left: 0;
  z-index: 3;
  display: flex;
  height: 6vh;
  width: 100%;
  background-color: transparent;
}

.sidepaneToggle {
  cursor: pointer;
  padding: 10px;
}

.hidden {
  display: none;
}