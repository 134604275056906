@import '/src/styles/theme.scss'; 


.wrapper {

  background-color: #bdbdbd; /* Light, off-white base color */
  //background-image: 
    //radial-gradient(circle at 10px 10px, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0) 40px), /* Subtle dot pattern */
    //url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAmEBgqBd3qUAAAAASUVORK5CYII="); /* Noise texture */
  background-size: 100px 100px, auto; /* Control size of patterns */
  background-blend-mode: overlay;
  
  height: 80vh;
  padding: 20px;
  position: relative;
  width: 30vw;
  display: flex;
  @media (max-width: $breakpoint-iphone14promax) {
    width: auto;
  }
}

.contentWrapper {
  margin-top: auto;
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  scrollbar-width: none; 
}

.contentWrapper::-webkit-scrollbar {
  display: none;
}

.userMsg {
  background-color: #233752;
  //background-color: #ff0000;
  width: 70%;
  margin-left: auto;
  border-radius: 15px;
  padding: 15px;

  display: flex;
  flex-direction: column;
}

.peerMsg {
  width: 70%;
  background-color: #607DA4;
  margin-right: auto;
  border-radius: 15px;
  padding: 15px;

  display: flex;
  flex-direction: column;
}

.logoWrapper {
  padding-bottom: 10vh;
}

.timestamp {
  padding-top: 20px;
  margin-left: auto;
  
  :first-child {
    color: white;
  }
}

.header {
  position: absolute;

  @media (max-width: $breakpoint-iphone14promax) {
    top: 14px;
    left: 15px;
  }
}