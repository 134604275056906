
.contentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.row {
  margin-top: 5vh;
  padding: 20px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 20px;
}

.column {
  display: flex;
  flex-direction: column; 
  justify-content: start;
  align-items: start;
}

.picture {
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

.listWrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: start;
  gap: 20px;
  margin: 20px;
}

.divider {
  border: 1px solid #EEEEEE;
  width: 90%;
}

