
@import '/src/styles/theme.scss'; 

.markerWrapper {
  position: relative;
  background-color: $main-white;
  height: 40px;
  padding:  0px 35px 0px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.3s ease; 
}

.markerWrapperClosed {
  position: relative;
  background-color: $main-white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.3s ease; 
  background-color: $primary-color;
  .content {
    z-index: 2;
    background-color: $main-white;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .pinpoint {
    bottom: -5px;
    background-color: $primary-color;
  }
}

.pinpoint {
  position: absolute;
  transform: rotate(45deg);
  background-color: $main-white;
  width: 20px;
  height: 20px;
  bottom: -10px;
}

.btnLeft {
  position: absolute;
  top: -1px;
  left: -20px;
  background-color: $primary-color;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid white;
}

.btnRight {
  top: -1px;
  right: -20px;
  position: absolute;
  background-color: $primary-color;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid white;
}