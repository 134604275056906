.hoverElement {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .tooltip {
    position: absolute;
    bottom: 120%;  // Position the tooltip above the element
    left: 50%;
    transform: translateX(-50%);
    padding: 8px;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 12px;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
  }

  &:hover .tooltip {
    opacity: 1; // Show tooltip on hover
  }
}
