@import '/src/styles/theme.scss'; 


.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end; 
}

.select {
    appearance: none;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    font-size: 16px;
    &:focus {
      border-color: #007bff;
      outline: none;
    }

    background-repeat: no-repeat;
    background-position: right 10px center;

    @media (max-width: $breakpoint-iphone14promax) {
      width: 60%;
    }

  }