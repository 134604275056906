@import '/src/styles/theme.scss'; 


.wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #0097FE;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;

}

.wrapperContent {
  padding: 5vh;
  z-index: 1;

  @media (max-width: $breakpoint-iphone14promax) {
    padding: 50px;
  }
}

.familyPic {
  border-radius: 50%;
  width: 310px;
  height: 310px;
  background-color: $main-white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  gap: 20px;
  display: flex;
  justify-content: start;
  align-items: start;

  @media (max-width: $breakpoint-iphone14promax) {
    flex-direction: column;
    align-items: center;
  }
}

.icon {
  width: 60px;
  height: 60px;

  @media (max-width: $breakpoint-iphone14promax) {
    width: 34px;
    height: 34px;
  }
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 5px;
}

.columCentered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px
}

.btnWrapper {
  margin-top: -80px;
  padding-bottom: 5vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0;


  @media (max-width: $breakpoint-iphone14promax) {
    margin-top: 5vh;
  }
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr .4fr;

  @media (max-width: $breakpoint-iphone14promax) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.column1{
  padding-right: 15%;
  
  @media (max-width: $breakpoint-iphone14promax) {
    padding-right: 0;
  }
}

.column2 { 
  padding-right: 10%;

  @media (max-width: $breakpoint-iphone14promax) {
    padding-right: 0;
  }
}

.backBtn {
  z-index: 2;
  position: absolute;
  top:15px;
  left: 15px;
  background-color: $main-white;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.slidersWrapper {
  @media (max-width: $breakpoint-iphone14promax) {
    padding: 5vw;
  }
}
.circle {
  position: absolute;
  width: 1000px;
  height: 1000px;
  right: -500px;
  top: -850px;
  border-radius: 50%;
  background-color: #00C0FE;
  box-shadow: 0 0 15px rgba(0, 192, 254, 0.5);
}