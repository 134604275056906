@import '/src/styles/theme.scss'; 

.wrapper {
  width: 60vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  position: relative;
  padding: 0;
  background-color: pink;

  background-image: url('../../assets/rendezvous.png');
  background-size: cover;      
  background-position: center; 
  background-repeat: no-repeat;

  @media (max-width: $breakpoint-iphone14promax) {
    width: auto;
    background-image: url('../../assets/rendezvous.png');
    background-size: cover;      
    background-position: left; 
    background-repeat: no-repeat;
  }
}

.blankWrapper {
  width: 60vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  position: relative;
  padding: 0;
  background-color: #4D5DAE;

  @media (max-width: $breakpoint-iphone14promax) {
    width: auto;
    padding: 2vw;
  }
}

.contentWrapper {
  width: 50%;
  height: 100vh;
  padding: 5vh 2vw 5vh 2vw;
  opacity: 0.9;
  margin-left: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $breakpoint-iphone14promax) {
    width: auto;
    margin-top: 30vh;
  }
}


.footer {
  position: absolute;
  bottom: 5px;
  padding-top: auto;
}
